@import '../../../../assets//common_styles/vars_mixins';

.sertificates {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;  
    // max-height: 100vh;

    @media(max-width: 576px){
        justify-content: center;
    }
}

.sert {
    
    position: relative;
    display: inline-block;
    flex: 0 1 calc((100% - 80px) / 4);
    aspect-ratio: 1240/1754;
    transition: .3;
    border: 1px solid rgba($grey_clr, .5);

    @media(max-width: 768px){
        flex: 0 1 calc((100% - 40px) / 2);
    }

    @media(max-width: 576px){
        flex: 0 1 100%;
        max-width: 300px;
    }
}

.imgWr {
    position: relative;
    width: 100%;
    height: 100%;

    img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.mask {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: .6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    gap: 15px;
    font-size: 20px;

    transition: .3;

    svg {
        fill: #fff;
        width: 55px;
        height: 55px;
    }
}

.sert:hover {
    .mask {
        opacity: 1;
        visibility: visible;
    }
}
@import '../../../../assets/common_styles/vars_mixins';

.adminSection {
    padding-top: 35px;
}

.titleCreateBtnWr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;

    margin-bottom: 35px;

    .pageTitle {
        margin: 0px;
    }

    .createBtn {
        flex-direction: row-reverse;
        background-color: $green_clr;

        &:hover {
            background-color: rgba($color: $green_clr, $alpha: .9);
        }
    }
}

.smallCommonErr {
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 6;
    width: 100%;
    padding: 5px 12px;
    
    font-size: 11px;
    transition: $transition_val;
    background-color: $lgrey_clr;
    background-color: $err_clr;
    color: $lgrey_clr;
    box-shadow: $boxshadow_val;
    opacity: 0;
    visibility: hidden;
}

.smallCommonErr.visible {
    opacity: 1;
    visibility: visible;
}

.articlesContainer {
    border: 1px solid $dblue_clr;
    width: 100%;
}

.pageTitle {
    margin-bottom: 25px;
}

.draggableArtsWr {
    padding: 0px;
    margin: 0px;
    position: relative;

    .preloaderWr,
    .reordErr {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 4;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #fff, $alpha: .6);
        cursor: not-allowed;

        z-index: -1;
        opacity: 0;
        visibility: hidden;

    }
}

.draggableArtsWr.tmpDisabled {

    .preloaderWr,
    .reordErr {
        opacity: 1;
        visibility: visible;
    }

    &::before {
        z-index: 3;
        opacity: 1;
        visibility: visible;
    }
}

.articleRow {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0px auto;
}



.tablesTitles {
    background-color: $lblue_clr;
    font-weight: 700;
    font-size: 13px;
}

.dragEl,
.title,
.published,
.edit,
.trash {
    padding: 6px 12px;
    outline: 1px solid $dblue_clr;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 23px;
        height: 23px;
    }
}






.dragEl {
    flex: 0 0 5%;
    cursor: grab;
}

.title {
    flex-grow: 1;
    justify-content: start;
    font-size: 16px;
}

.tablesTitles .title {
    justify-content: center;
}

.published {
    flex: 0 0 10%;
}

.edit {
    flex: 0 0 10%;
}

.trash {
    flex: 0 0 10%;
}

.editBtn,
.trashBtn,
.publOnBtn,
.publOffBtn {
    padding: 6px;
    width: 42px;
    height: 42px;
}

.trashBtn,
.publOffBtn {
    background-color: $err_clr;

    &:hover {
        background-color: rgba($color: $err_clr, $alpha: .9);
    }
}

.publOnBtn {
    background-color: $green_clr;

    &:hover {
        background-color: rgba($color: $green_clr, $alpha: .9);
    }
}

.paginationBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}

.lettWr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 60vh;

    .noArtsLett {
        font-weight: 700;
    }
}
@import './nullStyle';
@import './vars_mixins';
@import './textEditor';

body {
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  font-size: 18px;

  font-family: Helvetica, Tahoma, sans-serif;
  line-height: 1.4;
  color: $dblue_clr;

  background-color: #fff;

  @media(max-width: 992px) {
    font-size: 15px;
  }
}

body.lock {
  height: 100vh;
  overflow-y: hidden;
}

#root {
  width: 100%;
}


.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  width: 100%;
  // overflow-x: hidden;
}

header,
footer {
  flex: 0 1 auto;
}

main {
  flex: 1 1 auto;
}

[class*="__container"] {
  // max-width: 1320px;
  width: 100%;
  max-width: 1400px;
  padding: 0px 15px;
  margin: 0px auto;
}

.section {
  padding: 80px 0px;

  @media(max-width: 768px) {
    padding: 60px 0px;
  }
}

.dsection {
  // background-color: rgba($color: $dblue_clr, $alpha: .05);
  background-color: rgba($color: $dblue_clr, $alpha: .03);
}

h1 {
  font-size: 40px;


  @media(max-width: 1100px) {
    font-size: 35px;
  }

  @media(max-width: 768px) {
    font-size: 25px;
  }

  @media(max-width: 450px) {
    font-size: 23px;
  }
}

h2 {
  font-size: 40px;
  margin-bottom: 55px;

  @media(max-width: 768px) {
    font-size: 27px;
    margin-bottom: 32px;
  }

  @media(max-width: 450px) {
    font-size: 25px
  }
}

a {
  text-decoration: none;
}

.effect_on_hover:hover {
  transition: .4s;
  box-shadow: 4px 4px 29px 0px rgba(34, 60, 80, 0.2);
}

/* Scrollbarr*/

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background-color: $dblue_clr;
  box-shadow: 0 2px rgba($color: $dblue_clr, $alpha: .3) inset;
}

::-webkit-scrollbar-thumb {
  background-color: $lblue_clr;
  border-radius: 0px;
}

.err {
  color: $err_clr;
  font-weight: 700;
}

.succ {
  color: $green_clr;
  font-weight: 700;
}
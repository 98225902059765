//$dblue_clr: #13385F;
$ddblue_clr:#050E18;
$dblue_clr:#183864;

$mblue_clr: #0F7DF7;

$lblue_clr: #8CCDF3;

$grey_clr: #7E7E7E;
$lgrey_clr: #EBEBEB;



// Цвета
//$red_clr: #E80E0E;
$err_clr: #C53030;
$green_clr: #43B968;




$transition_val: 0.4s;

$boxshadow_val: 4px 4px 29px 0px rgba(34, 60, 80, 0.2);
// $border_r: 15px;
$border_r_small: 5px;


@mixin img-absolute {
    position: relative;

    &>img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

// @mixin thin-grey-scrollbar {
//     &::-webkit-scrollbar {
//         width: 4px;
//     }

//     &::-webkit-scrollbar-track {
//         background-color: transparent;
//     }

//     &::-webkit-scrollbar-thumb {
//         background-color: rgba($color: #000000, $alpha: .3);
//         border-radius: 3px;
//     }
// }
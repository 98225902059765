@import '../../../../assets//common_styles/vars_mixins';

.categories {
    display: flex;
    gap: 60px;
    justify-content: center;
    flex-wrap: wrap;

    @media(max-width:576px){
        gap: 30px;
    }
}

.category {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba($color: #76B0EF, $alpha: .3);
    max-width: 500px;
    border: 1px solid rgba($grey_clr, .5);
    transition: .3;
}

.coverWr {
    position: relative;
    width: 100%;
    aspect-ratio: 585/330;
    overflow: hidden;

    img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: .3s;
    }
}

// .coverWr:hover {
//     img {
//         transform: scale(1.1);
//     }
// }

.nameWr {
    flex-grow: 1;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
    color: $dblue_clr;
   // font-size: 20px;

    svg {
        opacity: 0;
        visibility: hidden;
        transition: .3;
        flex: 0 0 35px;
        width: 35px;
        height: 35px;
    }
}

.category:hover{
    .nameWr svg{
        opacity: 1;
        visibility: visible;
    }
}
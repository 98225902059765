@import '../../../assets//common_styles/vars_mixins';

.container {
    padding: 70px 15px;
}

.catalogItems {
    display: flex;
    flex-wrap: wrap;
    gap: 45px;

    @media(max-width: 1200px) {
        gap: 35px;
    }

    @media(max-width: 768px) {
        justify-content: center;
    }
}

.catalogCard {
    // padding-top: 15px;
    position: relative;
    flex: 0 1 calc((100% - 180px) / 5);
    min-width: 200px;
    cursor: pointer;
    background-color: rgba($color: #76B0EF, $alpha: .2);
    border: 1px solid rgba($grey_clr, .5);
    color: $dblue_clr;

    @media(max-width: 1200px) {
        flex: 0 1 calc((100% - 105px) / 4);
    }

    @media(max-width: 992px) {
        flex: 0 1 calc((100% - 70px) / 3);
    }

    @media(max-width: 700px) {
        flex: 0 1 calc((100% - 130px) / 2);
    }

    @media(max-width: 600px) {
        flex: 0 1 calc((100% - 35px) / 2);
    }

    @media(max-width: 500px) {
        flex: 0 1 100%;
        max-width: 300px;
    }

    // @media(max-width: 576px){
    //     // flex: 0 1 100%;
    //     min-width: 260px;
    // }

    // @media(max-width: 400px){
    //     flex: 0 1 90%;
    // }

    svg {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        bottom: 5px;
        right: 5px;
        transition: .3s;
    }
}


.catalogCard:hover {
    svg {
        opacity: 1;
        visibility: visible;
    }
}

.coverWr {
    background-color: #fff;
    position: relative;
    width: 100%;
    aspect-ratio: 585/460;

    img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        // object-fit: contain;
        object-position: center right;
    }
}

.name {
    padding: 20px;
    font-size: 15px;
    font-weight: 600;
}

.title {
    margin-bottom: 40px;
}

ul.breadcrumbs {
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    a {
        color: $dblue_clr;
    }

    span {
        color: $mblue_clr;
    }


    li {
        position: relative;
        margin-bottom: 12px;
    }

    // li:not(:last-child) {
    //     padding-right: 20px;
    // }

    // li:not(:last-child)::before {
    //     content: '|';
    //     position: absolute;
    //     top: 0px;
    //     right: 0px;
    // }

    // li:not(:first-child) {
    //     margin-left: 14px;
    // }

    li:not(:first-child) {
        padding-left: 20px;
    }

    li:not(:first-child)::before {
        content: '|';
        position: absolute;
        top: 0px;
        left: 0px;
    }

    li:not(:last-child) {
        margin-right: 14px;
    }
}

.modalWind {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    z-index: 100;
    background-color: rgba($color: #000000, $alpha: .5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .4s;

    .modalWindContent {
        position: relative;
        background-color: #fff;
        padding: 40px;
        border: 2px solid $dblue_clr;
        overflow-y: auto;

        width: 100%;
        // height: 100%;
        max-width: 800px;
        max-height: 650px;

        @media(max-width:768px) {
            // height: 100vh;
            // max-height: 100vh;
            padding: 40px 20px;
        }
    }

    .modalWindContent::-webkit-scrollbar {
        width: 4px;
    }

    .modalWindContent::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .modalWindContent::-webkit-scrollbar-thumb {
        background-color: rgba($color: #000000, $alpha: .3);
        border-radius: 3px;
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        padding: 3px;

        svg {
            width: 35px;
            height: 35px;
        }
    }

    .nameModal {
        font-size: 25px;
        // margin: 0px;
        margin-bottom: 30px;

        @media(max-width:768px) {
            font-size: 18px;
        }
    }

    .modalImgWr {
        position: relative;
        max-width: 330px;
        aspect-ratio: 585/460;
        margin-bottom: 30px;
        background-color: #fff;

        img {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center right;
        }
    }

    .modalDesc {
        position: relative;
        padding-left: 40px;
        margin-bottom: 30px;

        .paragraph {
            // margin-bottom: 12px;
        }
    }

    .modalDesc::before {
        content: '';
        position: absolute;
        top: 7px;
        left: 0px;
        display: block;
        width: 27px;
        height: 2px;
        background-color: $mblue_clr;
        ;
    }

    .moreInfo {
        display: flex;
        gap: 12px;
        align-items: center;
        flex-wrap: wrap;
        font-weight: 600;
        color: $mblue_clr;
    }

    .moreInfo:hover span {
        text-decoration: underline;
    }
}

.modalWind.open {
    opacity: 1;
    visibility: visible;
}
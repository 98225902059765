@import '../../../assets/common_styles/vars_mixins';


.preloaderBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 17px;
    font-weight: 700;
    // padding: 40px 0px;
    width: 100%;
    font-size: 16px;
    text-align: center;
    
    .svgWr{
        width: 100%;
        height: 100%;
    }

    img {
        width: 150px;
        height: 150px;
    }
}
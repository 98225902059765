.footer {
    padding: 15px 0px;
    background-color: #000;
    color: #fff;
}

.inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    font-weight: 600;

    @media(max-width: 576px) {
        justify-content: center;
        text-align: center;
    }
}

.right a {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // flex-direction: column-reverse;
    gap: 12px;
    font-weight: 500;
    font-size: 13px;

    img{
        height: 17px;
    }
}

.right a:hover span{
    text-decoration: underline;
}
@import '../../../../../../assets/common_styles/vars_mixins';
@import '../../../../../../assets/common_styles/modules/customInputs.module.scss';

.titleInpWr {
    margin-bottom: 15px;
}

.coverVisDateWr {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-bottom: 40px;

    @media(max-width: 768px) {
        flex-wrap: wrap;
        gap: 30px;
    }

    .visDateWr {
        flex-grow: 1;

        @media(max-width: 768px) {
            flex: 1 1 100%;
        }
    }
}

.editorWr {
    margin-bottom: 50px;
}

.submitBtn {
    width: 100%;
    margin-bottom: 12px;
}



.succOrErr {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-items: center;
    gap: 7px;

    opacity: 0;
    visibility: hidden;

    span {
        display: block;
        font-size: 12px;
        font-weight: 600;
       
        // height: 15px;
        white-space: nowrap;
        overflow: hidden;
    }
}

.succOrErr.visible {
    opacity: 1;
    visibility: visible;
}

.resetFormBtn {
    flex-direction: row-reverse;
    margin-left: auto;
    margin-bottom: 15px;
    margin-top: -15px;
    background-color: $err_clr;

    &:hover {
        background-color: rgba($color: $err_clr, $alpha: .9);
    }
}
@import '../../../../assets/common_styles/vars_mixins';

.header {
    background-color: $dblue_clr;
    padding: 12px 0px;
    box-shadow: $boxshadow_val;
}

h1.title {
    font-size: 25px;
    color: #fff;
    font-weight: 600;

    @media(max-width: 450px) {
        font-size: 17px
    }
}
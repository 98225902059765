.banner {
    position: relative;
    // /height: 100vh;
    min-height: 100vh;
    padding-top: 400px;

    @media(max-width: 768px){
        padding-top: 250px;
    }

    @media(max-width: 576px){
        height: auto;
        min-height: 100vh;
    }

    &>div {
        margin-top:6%;
        height: 200px;
        // height: 100%;

        @media(max-width: 768px){
            margin-top: 0px;
            //font-size: 25px;
        }
    }


    &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: .3);
    }
}

.bannerContent {
    position: relative;
    z-index: 2;
    //display: flex;
    //align-items: center;
    //margin-top: 100px;
    max-width: 800px;
    // height: 100%;

    @media(max-width: 768px){
        max-width: 450px;
    }


    h1 {
        color: #fff;
        //font-size: 40px;
        font-weight: 800;
        text-transform: uppercase;

        // @media(max-width: 1100px){
        //     font-size: 35px;
        // }

        // @media(max-width: 768px){
        //     font-size: 25px;
        // }
    }
}
@import '../../../../assets//common_styles/vars_mixins';

.contacts {
    padding-bottom: 0px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        width: 100%;
        height: 100%;
        // background-color: rgba($color: #2D71B0, $alpha: .2);
        // background-color: rgba($color: #016E99, $alpha: .2);
        background-color: rgba($color: #000, $alpha: .6);
    }

    color: #fff;
}

.container {
    position: relative;
    z-index: 1;
}

.content {
    display: flex;
    gap: 60px;
    // align-items: center;

    @media(max-width: 768px) {
        flex-wrap: wrap;
    }
}

.contactsList {
    flex: 0 1 50%;
    padding-left: 0px;
    list-style: none;

    @media(max-width: 768px) {
        flex: 0 1 100%;
    }

    .contactItem {
        padding: 25px 20px;
        border-bottom: 1px solid #fff;

        @media(max-width: 768px) {
            padding: 15px 0px;
        }
    }

    .contactItem:not(:last-child) {
        margin-bottom: 15px;
    }

    .lett {
        display: block;
        margin-bottom: 15px;
        font-weight: 700;
        font-size: 22px;

        @media(max-width: 768px) {
            font-size: 20px;
        }
    }

    .info {
        display: block;
        color: #fff;
        font-size: 21px;
        font-weight: 700;

        svg{
            margin-right: 12px;
        }

        @media(max-width: 768px) {
            font-size: 18px;
        }
    }

    .contactItem a {
        transition: .4s;
    }

    .contactItem a:hover {
        text-decoration: underline;
        //background-color: rgba($color: #000000, $alpha: 1.0);
    }
}

.mapWr {
    position: relative;
    flex: 0 1 50%;
    min-height: 550px;

    @media(max-width: 768px) {
        flex: 0 1 auto;
        min-height: 350px;
         margin: 0px -15px;
        width: calc(100% + 30px);
    }

    .mapWr_inner {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: calc(50vw - 30px);
        max-width: 1500px;

        @media(max-width: 768px) {
            width: 100%;
        }
    }
}
@import '../../../../assets//common_styles/vars_mixins';

.header {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 10;
}



.header_top {

    .top_row1 {
        // background-color: #fff;
        display: flex;

        .left {
            position: relative;
            flex: 0 1 300px;
            background-color: #fff;

            @media(max-width: 992px) {
                flex: 0 0 200px;
            }

            @media(max-width: 768px) {
                flex: 0 0 150px;
            }

            @media(max-width: 450px) {
                flex: 0 0 100px;
            }


            .logoWr {
                padding: 17px 30px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                // aspect-ratio: 116/55;


                @media(max-width: 992px) {
                    padding: 17px 12px;
                }

                @media(max-width: 450px) {
                    padding: 8px 10px;
                }

                svg {
                    height: 85px;
                    width: 180px;


                    @media(max-width: 992px) {
                        height: 57px;
                        width: 120px;
                    }

                    @media(max-width: 450px) {
                        height: 38px;
                        width: 80px;
                    }
                }
            }
        }

        .left::before {
            content: '';
            position: absolute;
            right: 99%;
            top: 0px;
            display: block;
            background-color: #fff;
            width: 50vw;
            height: 100%;

        }


        .right {
            position: relative;
            flex: 1 1 auto;
            padding: 10px 30px;
            display: flex;
            justify-content: end;
            align-items: center;
            text-transform: uppercase;
            color: #fff;
            font-weight: 600;

            @media(max-width: 992px) {
                font-size: 14px;
            }

            @media(max-width: 768px) {
                font-size: 11px;
                font-weight: 700;
                padding: 10px 0px 0px 20px;
            }

            @media(max-width: 450px) {
                font-size: 10px
            }

            p {
                margin-left: auto;
            }

            .paragraph_v1 {
                display: block;

                @media(max-width: 576px) {
                    display: none;
                }
            }

            .paragraph_v2 {
                display: none;

                @media(max-width: 576px) {
                    display: block;
                }
            }

            .topSector {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 50px;
                height: 50px;

                img {
                    position: absolute;
                    top: 0px;
                    left: -1px;
                    width: 100%;
                    height: 100%;
                }

            }
        }

    }

    .top_row2 {
        // background-color: #fff;
        display: flex;

        @media(max-width: 768px) {
            display: none;
        }

        .left {
            position: relative;
            flex: 0 1 300px;
            padding: 10px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            white-space: nowrap;
            //font-size: 16px;

            color: #fff;


            @media(max-width: 992px) {
                flex: 0 0 200px;
                font-size: 15px;
                padding: 5px;
            }

            // @media(max-width: 992px){
            //     font-size: 14px;
            // }

            .topSector {
                position: absolute;
                bottom: 0px;
                right: 0px;
                width: 60px;
                height: 60px;
                transform: rotate(180deg);

                img {
                    position: absolute;
                    top: 0px;
                    left: -1px;
                    width: 100%;
                    height: 100%;
                }

            }
        }

        .right {
            position: relative;
            background-color: #fff;
            position: relative;
            flex: 1 1 auto;
            padding: 10px 30px;
            // overflow-x: hidden;
            // overflow-y: visible;

            .imgBgWr {
                position: absolute;
                top: -25px;
                left: 15%;
                //transform: translateX(50%);
                height: 100%;
                width: 85%;

                @media(max-width:992px) {
                    left: 0%;
                }


                img {
                    height: calc(100% + 25px);
                }
            }

            p {
                position: relative;
                z-index: 2;
                text-align: right;
                font-size: 15px;
                font-weight: 600;
                color: $grey_clr;

                @media (max-width: 992px) {
                    font-size: 13px;
                }
            }

            p.subtitle {
                color: $dblue_clr;
                font-size: 22px;
                font-weight: 800;
                margin-bottom: 7px;

                @media (max-width: 992px) {
                    font-size: 15px;
                }
            }
        }

        .right::before {
            content: '';
            position: absolute;
            left: 99%;
            top: 0px;
            display: block;
            background-color: #fff;
            width: 50vw;
            height: 100%;

        }

    }

}














.header_bottom {
    .headerUl {
        display: flex;
        justify-content: end;
        gap: 20px;
        flex-wrap: wrap;
        padding-top: 30px;


        @media(max-width: 768px) {
            position: fixed;
            top: 0px;
            left: 0px;
            height: 100vh;
            background-color: rgba($color: #000000, $alpha: 1);
            // max-width: 450px;
            // min-width: 320px;
            width: 450px;
            flex-direction: column;
            justify-content: center;
            padding: 40px 40px;
            gap: 5px;
            overflow-y: auto;
            overflow-x: hidden;
            flex-wrap: nowrap;
            transition: .4s;
            transform: translateX(0%);

            &.hide {
                transform: translateX(-110%);
            }
        }


        @media(max-width:500px) {
            width: 100vw;
        }

        .closeBtn {
            position: absolute;
            top: 0px;
            right: 0px;
            font-size: 40px;
            color: #fff;
            padding: 3px;
            background-color: rgba($color: #fff, $alpha: .2);
            width: 55px;
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
            cursor: pointer;
        }

        a {
            display: inline-block;
            padding: 10px 20px;
            font-size: 25px;
            color: #fff;
            font-weight: 700;
            text-decoration: none;
            white-space: nowrap;
            transition: .3s;
            cursor: pointer;

            @media(max-width: 768px) {
                width: 100%;
                // font-size: 18px;
            }

            @media(max-width: 576px) {
                width: 100%;
                font-size: 20px;
            }

            // @media(max-width: 576px) {
            //                 font-size: 13px;
            //                 padding: 5px;
            //             }


        }

        a.active,
        a:hover {
            background-color: rgba($color: #fff, $alpha: .2);
            color: #fff;
        }
    }

    .openBtn {
        font-size: 40px;
        color: #fff;
        padding: 3px;
        background-color: rgba($color: #fff, $alpha: .4);
        width: 55px;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        cursor: pointer;
        margin-left: auto;
        margin-top: 15px;
        margin-bottom: 15px;

        &.hide {
            opacity: 0;
            visibility: hidden;
        }
    }


}

.blackHeader {
    position: relative;
    background-color: #000;

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: .4);
        z-index: -1;
    }

    .header_bottom {

        .headerUl {
            padding-top: 15px;
            padding-bottom: 15px;
        }

    }
}



//! =========================================  Старые стили для header_bottom
// .header_bottom {
//     .headerUl {
//         display: flex;
//         justify-content: end;
//         gap: 20px;
//         flex-wrap: wrap;
//         padding-top: 30px;

//         @media(max-width: 768px) {
//             gap: 5px;
//         }


//         a {
//             display: inline-block;
//             padding: 10px 20px;
//             font-size: 25px;
//             color: #fff;
//             font-weight: 700;
//             text-decoration: none;
//             white-space: nowrap;
//             transition: .3s;
//             cursor: pointer;

//             @media(max-width: 1400px) {
//                 font-size: 18px;
//             }

//             @media(max-width: 576px) {
//                 font-size: 13px;
//                 padding: 5px;
//             }
//         }

//         a.active,
//         a:hover {
//             background-color: rgba($color: #fff, $alpha: .2);
//             color: #fff;
//         }
//     }


// }

// .blackHeader {
//     position: relative;
//     background-color: #000;

//     &::before {
//         content: '';
//         position: absolute;
//         top: 0px;
//         left: 0px;
//         display: block;
//         width: 100%;
//         height: 100%;
//         background-color: rgba($color: #000000, $alpha: .4);
//         z-index: -1;
//     }

//     .header_bottom {

//         .headerUl {
//             padding-top: 15px;
//             padding-bottom: 15px;
//         }

//     }
// }
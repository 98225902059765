@import './vars_mixins';

.tox.tox-tinymce {
    border-radius: 0px;
    border: 2px solid $dblue_clr;

    &.tox-edit-focus{
        border: 2px solid rgba($color: $dblue_clr, $alpha: .9);
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
    }

    & .tox-edit-area::before{
        border-radius: 0px;
        border: 0px;
        border-top: 2px solid rgba($color: $dblue_clr, $alpha: .9);
        border-bottom: 2px solid rgba($color: $dblue_clr, $alpha: .9);
    }
}
@import '../../../assets/common_styles/vars_mixins';
@import '../../../assets/common_styles/modules/customInputs.module.scss';

.loginWr {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.loginTop {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    flex: 0 1 100%;

    svg {
        width: 35px;
        height: 35px;
    }

    .title {
        flex: 1 0 100%;
        text-align: center;
        font-size: 32px;
    }
}

.inputsWr {
    flex-grow: 1;
    max-width: 300px;
}

.loginBtn {
    width: 100%;
    margin-top: 30px;
}

.passwordInputGr {
    position: relative;

    .eyeWr {
        position: absolute;
        bottom: 18px;
        right: 8px;
        height: 41px;
        width: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
            width: 27px;
            height: 27px;
        }
    } 
}

.passwordInputGr.withError{
    color: $err_clr;
}

.errOnLogIn{
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
    height: 16px;
}
@import '../../../../assets//common_styles/vars_mixins';


.titleWr {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 55px;
    align-items: center;
    flex-wrap: wrap;

    @media(max-width: 768px) {
        margin-bottom: 32px;
    }

    h2 {
        margin-bottom: 0px;
    }

    a {
        font-size: 17px;
        font-weight: 600;
        display: flex;
        gap: 10px;
        align-items: center;
        color: $mblue_clr;
        margin-right: 15px;
    }

    a:hover{
        text-decoration: underline;
    }
}


.articles {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;

    @media(max-width: 1400px) {
        gap: 30px;
    }
}

.lettWr{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 355px;

    .noArtsLett{
        font-weight: 700;
    }
}
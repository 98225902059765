@import '../../../../assets/common_styles/vars_mixins';
@import '../../../../assets/common_styles/modules/customInputs.module.scss';


.adminSection {
    padding-top: 35px;
}

.goOnSiteBtn {
    width: 42px;
    height: 42px;
    margin-left: auto;
    padding: 8px;
    background-color: $mblue_clr;
    margin-bottom: 35px;

    &:hover {
        background-color: rgba($color: $mblue_clr, $alpha: .9);
    }
}

.titleBtnWr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-bottom: 35px;

    @media(max-width: 768px) {
        flex-wrap: wrap;
        gap: 30px;
    }

    .pageTitle {
        margin: 0px;
    }

    .goBackBtn {
        flex-direction: row-reverse;
        // margin-left: auto;
    }
}

.lettWr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 60vh;
}
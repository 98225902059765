
.text{
    font-size: 18px;

    p{
        text-indent: 1.5em; 
       text-align: justify;
    }

    p:not(:last-child){
        margin-bottom: 10px;
    }

    @media(max-width: 576px){
        font-size: 16px;
    }
}
@import '../../../../../../assets/common_styles/vars_mixins';

.cropAvatarModal {
    opacity: 0;
    visibility: hidden;

    position: fixed;
    z-index: 20;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $ddblue_clr, $alpha: .8);

    display: flex;
    justify-content: center;
    align-items: center;
    transition: $transition_val;

    // img {
    //     position: static;
    //     width: auto;
    //     height: auto;
    //     border-radius: 0px;
    // }
}

.cropAvatarModal.visible {
    opacity: 1;
    visibility: visible;
}

.modalContent {
    position: relative;
    padding: 30px 35px 35px 35px;
    background-color: #fff;
    width: 750px;
    max-width: 100vw;
    // height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    box-shadow: $boxshadow_val;

    @media(max-width:450px) {
        padding: 35px 20px;

    }
}

.closeBtn {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 27px;
    padding: 3px;
}

.closeBtnMask {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.title{
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 25px;
}

.lett{
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
}


.cropWr {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.cropBlock {
    max-height: 310px;
    max-width: 500px;
    box-shadow: $boxshadow_val;
}

.canvasBig {
    display: none;
    width: 1000px;
    aspect-ratio: 1000/620;
}

.updatePhotoBtn{
    width: 100%;
}
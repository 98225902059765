@import '../../../assets//common_styles/vars_mixins';

.container {
    padding: 70px 15px 90px 15px;
}

.title {
    margin-bottom: 40px;
}

ul.breadcrumbs {
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    a {
        color: $dblue_clr;
    }

    span {
        color: $mblue_clr;
    }


    li {
        position: relative;
        margin-bottom: 12px;
    }

    li:not(:first-child) {
        padding-left: 20px;
    }

    li:not(:first-child)::before {
        content: '|';
        position: absolute;
        top: 0px;
        left: 0px;
    }

    li:not(:last-child) {
        margin-right: 14px;
    }
}

.articles {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;

    @media(max-width: 1400px) {
        gap: 30px;
    }
}

.articlePageContent {
    .coverWr {
        position: relative;
        max-width: 1000px;
        // aspect-ratio: 1600/826;
        aspect-ratio: 1450/900;
        margin: 0px auto 30px auto;

        img {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            object-fit: cover;

        }
    }

    .date {
        flex-wrap: nowrap;
        white-space: nowrap;
        margin-bottom: 30px;
        text-align: right;
    }

    .title {
        font-size: 30px;
        margin-bottom: 50px;

        @media(max-width: 768px) {
            font-size: 24px;
        }

        @media(max-width: 450px) {
            font-size: 21px;
        }
    }

    .artText {
        position: relative;
        padding-left: 150px;

        @media(max-width: 576px){
            padding-left: 60px;
        }
        @media(max-width: 450px){
            padding-left: 0px;
        }
    }

    .artText::before {
        content: '';
        position: absolute;
        top: 10px;
        left: 50px;
        display: block;
        width: 50px;
        height: 4px;
        background-color: $mblue_clr;

        @media(max-width: 576px){
            left: 15px;
            width: 30px;
        }
        @media(max-width: 450px){
            left: 0px;
            width: 0px;
        }
    }

    .artText p:not(:last-child){
        margin-bottom: 20px;
    }

}






.article {
    flex: 1 0 calc((100% - 120px)/4);
    position: relative;
    max-width: 310px;
    border: 1px solid rgba($grey_clr, .5);
    color: $dblue_clr;

    display: flex;
    flex-direction: column;

    @media(max-width: 1400px){
        flex: 0 1 calc((100% - 90px)/4);
    }

    @media(max-width: 992px) {
        flex: 0 1 calc((100% - 60px)/3);
    }

    @media(max-width: 768px) {
        flex: 0 1 calc((100% - 30px)/2);
    }

    @media(max-width: 576px) {
        flex: 0 1 100%;
        max-width: none;
    }


    .imgWr {
        position: relative;
        aspect-ratio: 1600/826;

        img {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left top;
        }
    }

    .artText {
        position: relative;
        flex-grow: 1;
        padding: 20px 30px 30px 30px;
        background-color: rgba($color: #fff, $alpha: .9);
    }

    .date {
        font-size: 13px;
        flex-wrap: nowrap;
        margin-bottom: 15px;
    }

    .artName {
        font-size: 16px;
    }

    svg {
        position: absolute;
        bottom: 12px;
        right: 12px;
        transition: .3s;

        opacity: 0;
        visibility: hidden;
    }
}

.article:hover svg {
    opacity: 1;
    visibility: visible;
}


.paginationBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}

.lettWr{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 60vh;

    .noArtsLett{
        font-weight: 700;
    }
}
@import '../../../assets//common_styles/vars_mixins';

.pnf {
    a {
        display: inline-flex;
        flex-wrap: wrap;
        gap: 12px;
        align-items: center;
        color: $mblue_clr;
        font-weight: 700;
    }

    a:hover {
        text-decoration: underline;
    }
}